import React from "react"
import "./DropDown.scss"
import PropTypes from "prop-types"
import { Link } from "gatsby"
export default function DropDown({ title, subItems = [] }) {
  const [isActive, setIsActive] = React.useState(true)
  const handleDropDownToggle = () => {
    setIsActive(currentVal => !currentVal)
  }
  return (
    <div className="navbar-item nav-item has-dropdown is-hoverable">
      <Link to="/" className="navbar-link" onClick={handleDropDownToggle}>
        {title}
      </Link>
      <div
        className={`navbar-dropdown custom-navbar-dropdown ${
          isActive ? "dropdown-active" : ""
        }`}
      >
        {subItems.map((subItem, subIndex) => {
          return (
            <Link
              key={subIndex}
              to={`/products/${subItem.node.slug}`}
              className="navbar-item"
            >
              <div className=" is-flex navbar-item_wrapper">
                <span className="icon Green">
                  <i className="fas fa-certificate"></i>
                </span>
                <div>
                  <div className="product-name">{subItem.node.header}</div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

DropDown.propTypes = {
  title: PropTypes.string.isRequired,
  subItems: PropTypes.array.isRequired,
}
